import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Player } from 'gamedig';

export interface PlayerdialogProperties {
    open: boolean;
    handleClose: () => void;
    players?: Player[];
}

export const Playerdialog: FunctionComponent<PlayerdialogProperties> = ({open, handleClose, players}): JSX.Element => {

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Player Online</DialogTitle>
        <DialogContent>
            <List>
                {players?.map((player: Player) => <ListItem key={player.name}>
                    <ListItemText>{player.name}</ListItemText>
                </ListItem>)}
            </List>
        </DialogContent>
    </Dialog>
}