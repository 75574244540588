import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getRgbColorStringWithOpacity } from '../Theme';

export const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden'

    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '150%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
        overflow: 'hidden'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `
            repeating-linear-gradient(
                to bottom,
                ${getRgbColorStringWithOpacity('accentBlue', .8)} 0%,
                transparent 2px
            )
        `
    }
}));