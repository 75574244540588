import { Button, Grid, List, ListItem, ListItemText, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Alert, Rating } from '@material-ui/lab';
import { HttpError, HttpStatus } from '@sml86/httpjs';
import { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { War, Clan, Player, Round, Duel } from 'shared';
import { API } from '../../api/API';
import { styles } from './WarDetails.style';

export const WarDetails: FunctionComponent<RouteComponentProps<{war: string}>> = ({match}): JSX.Element => {
    const [clan, setClan] = useState<Clan>();
    const [war, setWar] = useState<War>();
    const [selectedPlayers, setSelectedPlayers] = useState<boolean[]>([]);
    const [playerNames, setPlayerNames] = useState<string[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<'success'|'warning'|'error'>();
    const [message, setMessage] = useState<string>();
    const classes = styles();

    const modes: (string|null)[] = [null, null, 'Two vs. Two', 'Three vs. Three', null, 'Five vs. Five'];

    async function init (id: number): Promise<void> {
        try {
            const clan: Clan = await API.getClan();
            clan.founded = new Date(clan.founded);
            setClan(clan);
            const war: War = await API.getWarById(id);
            setWar(war);
            initPlayerNames(war);
            const selected: boolean[] = [];
            for (let i = 0; i < clan.players.length; ++i) {
                for (const challenger of war.challenger.players) {
                    if (challenger.id === clan.players[i].id) {
                        selected[i] = true;
                        break;
                    }
                }
            }
            setSelectedPlayers(selected);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {

            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    async function startWar (): Promise<void> {
        if (!war) return;
        try {
            await API.startWar(war.id);
            const updatedWar: War = await API.getWarById(war.id);
            setWar(updatedWar);
            initPlayerNames(updatedWar);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {
                if (error.status === HttpStatus.Conflict) {
                    setMessage('Are you serious? You are already fighting a war!')
                } else if (error.status === HttpStatus.Forbidden) {
                    setMessage('Calm down! Only the challenger can start this war.')
                } else if (error.status === HttpStatus.NotModified) {
                    setMessage('You or your opponent are currently in a war.')
                }
            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    async function handlePlayerSelect (index: number): Promise<void> {
        if (!clan || !war) return;
        if (window.user?.id !== war.challenger.owner) return;
        const selected: boolean[] = selectedPlayers.slice();
        try {
            if (!selected[index]) await API.addPlayerToWar(war.id, clan.players[index]?.id);
            else await API.deletePlayerFromWar(war.id, clan.players[index]?.id);
            selected[index] = !selected[index];
            setSelectedPlayers(selected);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {
                if (error.status === HttpStatus.TooManyRequests) {
                    setMessage(`You cannot add more than ${war.mode} players! Cheater!`);
                }
            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    function initPlayerNames (war: War): void {
        const playerNames: string[] = [];
        for (const player of war.challenger.players) {
            playerNames[player.id] = player.name;
        }
        for (const player of war.opponent.players) {
            playerNames[player.id] = player.name;
        }
        setPlayerNames(playerNames);
    }

    useEffect(() => {
        if (war) {
            war.started = war.started && new Date(war.started);
            war.ended = war.ended && new Date(war.ended);
        }
    }, [war]);

    useEffect(() => {
        const id: number = parseInt(match.params.war);
        init(id);
    }, [match.params.war]);

    return <Grid item xs={12} className={classes.root}>
        {war && <Paper className={classes.paper}>
            <Typography variant="h4" className={classes.title}>{war.challenger.name} vs. {war.opponent.name}</Typography>
            {!war.started && !war.ended && <Grid container spacing={2}>
                <Grid item xs={12}><Typography className={classes.subheader}>Fighting {modes[war.mode]}</Typography></Grid>
                <Grid item xs={12} md={6}>
                    {clan && <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Level</TableCell>
                                    <TableCell>Aim</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clan.players.map((player: Player, index: number) => <TableRow
                                    key={`p${player.id}`}
                                    onClick={() => handlePlayerSelect(index)}
                                    className={`${selectedPlayers[index] && classes.selectedItem} ${classes.pointer}`}>
                                    <TableCell>{player.name}</TableCell>
                                    <TableCell>{player.level}</TableCell>
                                    <TableCell><Rating value={player.aim / 50} precision={0.2} readOnly /></TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>
                {window.user?.id === war.challenger.owner && <Grid item xs={12} md={6} className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" onClick={() => startWar()}>Start</Button>
                </Grid>}
            </Grid>}
            {war.started && war.ended && war.ended > new Date() && <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.subheader}>
                        Fighting {modes[war.mode]} &nbsp; | &nbsp;
                        Started {war.started.getDate()}/{war.started.getMonth() + 1}/{war.started.getFullYear()} {war.started.getHours()}:{war.started.getMinutes()} &nbsp; | &nbsp;
                        Ends {war.ended.getDate()}/{war.ended.getMonth() + 1}/{war.ended.getFullYear()} {war.ended.getHours()}:{war.ended.getMinutes()}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Level</TableCell>
                                    <TableCell>Aim</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {war.challenger.players.map((player: Player) => <TableRow key={`p${player.id}`}>
                                    <TableCell>{player.name}</TableCell>
                                    <TableCell>{player.level}</TableCell>
                                    <TableCell><Rating value={player.aim / 50} precision={0.2} readOnly /></TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Level</TableCell>
                                    <TableCell>Aim</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {war.opponent.players.map((player: Player) => <TableRow key={`p${player.id}`}>
                                    <TableCell>{player.name}</TableCell>
                                    <TableCell>{player.level}</TableCell>
                                    <TableCell><Rating value={player.aim / 50} precision={0.2} readOnly /></TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>}
            {war.started && war.ended && war.ended <= new Date() && <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.subheader}>
                        Fighting {modes[war.mode]} &nbsp; | &nbsp;
                        Started {war.started.getDate()}/{war.started.getMonth() + 1}/{war.started.getFullYear()} {war.started.getHours()}:{war.started.getMinutes()} &nbsp; | &nbsp;
                        Ended {war.ended.getDate()}/{war.ended.getMonth() + 1}/{war.ended.getFullYear()} {war.ended.getHours()}:{war.ended.getMinutes()} &nbsp; | &nbsp;
                        Winner {war.winner}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {war.rounds && <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell >Round</TableCell>
                                    <TableCell>Winner</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {war.rounds.map((round: Round) => <TableRow key={`r${round.round}`}>
                                    <TableCell>{round.round}</TableCell>
                                    <TableCell>{round.winner === 'challenger' ? war.challenger.name : war.opponent.name}</TableCell>
                                    <TableCell>
                                        <List>
                                            {round.duels.map((duel: Duel, index: number) => <ListItem key={`d${index}`}>
                                                <ListItemText>
                                                    {duel[2] === 'challenger' ? 
                                                        <Typography color={window.user?.id === war.challenger.owner ? 'secondary' : 'error'}>[{war.challenger.abbr}] {playerNames[duel[0]]} &nbsp; killed &nbsp; [{war.opponent.abbr}] {playerNames[duel[1]]}</Typography> :
                                                        <Typography color={window.user?.id === war.challenger.owner ? 'error' : 'secondary'}>[{war.opponent.abbr}] {playerNames[duel[1]]} &nbsp; killed &nbsp; [{war.challenger.abbr}] {playerNames[duel[0]]}</Typography>
                                                    }
                                                </ListItemText>
                                            </ListItem>)}
                                        </List>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>
            </Grid>}
        </Paper>}
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
        <Alert severity={severity}>
            {message}
        </Alert>
        </Snackbar>
    </Grid>
}