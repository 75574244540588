import { createStyles, makeStyles } from '@material-ui/core';
import { FunctionComponent, MutableRefObject, useEffect, useRef } from 'react';
import { getRgbColorString, getRgbColorStringWithOpacity } from '../Theme';

export interface CrackProperties {
    crackRef: MutableRefObject<string>;
}

export const Crack: FunctionComponent<CrackProperties> = ({crackRef}): JSX.Element => {
    const canvasRef: MutableRefObject<HTMLCanvasElement|null> = useRef(null);

    const styles = makeStyles(() => createStyles({
        root: {
            position: "absolute",
            left: -1000,
            top: -1000
        }
    }));

    useEffect(() => {
        if (!canvasRef.current) return;
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context: CanvasRenderingContext2D|null = canvas.getContext('2d');
        if (context) {
            context.fillStyle = getRgbColorString('secondaryMint');
            context.fillRect(10, 10, 200, 200);
            context.strokeStyle = getRgbColorString('accentPink');
            context.strokeRect(20, 20, 100, 100);
            
            context.lineWidth = 3;
            context.beginPath();
            context.moveTo(50, 50);
            context.lineTo(100, 150);
            context.lineTo(30, 60);
            context.closePath();
            context.stroke();
            
            context.lineWidth = 1;
            context.fillStyle = getRgbColorStringWithOpacity('accentOrange', .4);
            context.beginPath();
            context.moveTo(125, 125);
            context.lineTo(125, 45);
            context.lineTo(45, 125);
            context.closePath();
            context.stroke();
            context.fill();

            context.fillStyle = 'rgba(255, 255, 255, .2)';
            for (let i = 0; i < 7; ++i) {
                context.beginPath();
                context.arc(75, 75, 10 + 10 * i, 0, Math.PI * 2, true);
                context.fill();
            }

            context.strokeStyle = getRgbColorStringWithOpacity('accentBlue', .4);
            for (let i = 0; i < 20; ++i) {
                context.lineWidth = 1 + i/2;
                context.beginPath();
                context.moveTo(i * 10, 10);
                context.lineTo(i * 10, 180);
                context.closePath();
                context.stroke();
            }

            context.strokeStyle = 'rgb(0,0,0)';
            context.lineWidth = 1;
            context.strokeText(navigator.appCodeName, 30, 30);
            context.strokeText(navigator.appName, 30, 40);
            context.strokeText(navigator.platform, 30, 70);
            context.strokeText(navigator.product, 30, 80);
            context.strokeText(navigator.userAgent, 30, 90);
            context.strokeText(navigator.vendor, 30, 100);
            context.strokeText(navigator.vendorSub, 30, 110);
            context.strokeText(navigator.appVersion, 30, 120);
            context.strokeText(navigator.doNotTrack||'', 30, 130);
            context.strokeText(navigator.productSub, 30, 140);

            crackRef.current = canvas.toDataURL().substr('data:image/png;base64,'.length);
        }
    }, [crackRef]);

    return <canvas ref={canvasRef} className={styles().root}></canvas>;
}