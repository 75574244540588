import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getRgbColorString } from '../Theme';

export const styles = makeStyles((theme: Theme) => createStyles({
    area: {
        height: '100%',
        margin: 0,
        padding: 0
    },
    iconGreen: {
        color: theme.palette.secondary.main
    },
    iconRed: {
        color: theme.palette.error.main
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: '.8rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)'
    },
    headline: {
        color: theme.palette.text.primary,
        textShadow: '.2em .2em .2em rgb(0, 0, 0)'
    },
    logo: {
        width: '50%',
        height: 'auto',
        padding: '0 25%'
    },
    heart: {
        color: getRgbColorString('accentPink')
    },
    link: {
        cursor: 'pointer'
    },
    linkColor: {
        color: theme.palette.secondary.main
    }
}));