import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => createStyles({
    title: {
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    subtitle: {
        color: theme.palette.text.primary,
        fontSize: '1.3rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    sectiontitle: {
        color: theme.palette.text.primary,
        fontSize: '1.2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    formContainer: {
        padding: theme.spacing(2)
    },
    label: {
        color: 'rgba(255, 255, 255, .8)',
        fontSize: '.75rem'
    }
}));