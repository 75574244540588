import { FunctionComponent, useEffect, useState } from 'react';
import { Ranking as RankingData, Rankings } from 'shared';
import { Avatar, Grid, Icon, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core';
import { styles } from './Ranking.style';
import { API } from '../../api/API';
import { MedalIcon } from '../../icon/MedalIcon';
import { TrophyIcon } from '../../icon/TrophyIcon';

export const Ranking: FunctionComponent = (): JSX.Element => {
    const [rankings, setRankings] = useState<Rankings>();
    const classes = styles();

    async function getRankings (): Promise<void> {
        const rankings: Rankings = await API.getRankings();
        setRankings(rankings);
    }

    useEffect(() => {
        getRankings();
    }, []);

    return <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12}>
            <Paper>
                <Typography className={classes.title}>Ranking</Typography>
                {rankings && <List>
                    {rankings.map((ranking: RankingData, index: number) => <ListItem key={ranking.id}>
                        {index > 2 && <ListItemAvatar><Avatar className={classes.avatar}>{index + 1}</Avatar></ListItemAvatar>}
                        {index === 2 && <ListItemIcon><Icon className={classes.bronze}><MedalIcon /></Icon></ListItemIcon>}
                        {index === 1 && <ListItemIcon><Icon className={classes.silver}><MedalIcon /></Icon></ListItemIcon>}
                        {index === 0 && <ListItemIcon><Icon className={classes.gold}><TrophyIcon /></Icon></ListItemIcon>}
                        <ListItemText primary={ranking.name} secondary={`${ranking.wins} wins in ${ranking.fights} wars`} />
                    </ListItem>)}
                </List>}
            </Paper>
        </Grid>
    </Grid>
}