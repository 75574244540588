import { Button, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { Alert, Rating } from '@material-ui/lab';
import { HttpError, HttpStatus } from '@sml86/httpjs';
import { FormEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Clan as ClanData, Player } from 'shared';
import { API } from '../../api/API';
import { styles } from './Clan.style';

export const Clan: FunctionComponent = (): JSX.Element => {
    const [clan, setClan] = useState<ClanData>();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<'success'|'warning'|'error'>();
    const [message, setMessage] = useState<string>();
    const nameRef = useRef<HTMLInputElement>();
    const abbrRef =useRef<HTMLInputElement>();
    const classes = styles();

    async function getClan (): Promise<void> {
        try {
            const clan: ClanData = await API.getClan();
            clan.founded = new Date(clan.founded);
            if (window.user) window.user.clan = clan.id;
            setClan(clan);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {

            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    async function createClan (name: string, abbr: string): Promise<void> {
        try {
            const clan: ClanData = await API.createClan(name, abbr);
            clan.founded = new Date(clan.founded);
            if (window.user) window.user.clan = clan.id;
            setClan(clan);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {
                if (error.status === HttpStatus.Conflict) {
                    setMessage(`A clan with the name ${name} does already exist! Invent something own!`)
                }
            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    async function handleSubmit (e: FormEvent) {
        e.preventDefault();
        if (nameRef.current?.value && abbrRef.current?.value) {
            createClan(nameRef.current.value, abbrRef.current.value);
        } else {
            setSeverity('error');
            setMessage('You need to fill in Clan Name and Abbreviation!');
            setShowAlert(true);
        }
    }

    useEffect(() => {
        getClan();
    }, []);

    return <Grid container spacing={0} className={classes.root}>
        {clan && <><Grid item xs={12} className={classes.head}>
            <Paper className={classes.headInner}>
                <Typography className={classes.title}>{clan.name}</Typography>
                <Typography className={classes.hint}>{clan.abbr} &nbsp; | &nbsp; Since {clan.founded?.getDay()}/{clan.founded?.getMonth() + 1}/{clan.founded?.getFullYear()}</Typography>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell>Aim</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clan.players.map((player: Player) => <TableRow key={player.id}>
                            <TableCell>{player.name}</TableCell>
                            <TableCell>{player.level}</TableCell>
                            <TableCell><Rating value={player.aim / 50} precision={0.2} readOnly /></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid></>}
        {!clan && <Grid item xs={12}>
            <Paper className={classes.headInner}>
                <Typography className={classes.title}>Found your new clan!</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}><TextField name="name" label="Name" helperText="How is your clan called?" inputRef={nameRef} /></Grid>
                        <Grid item xs={6}><TextField name="abbr" label="Abbreviation" helperText="How is your clan abbreviated?" inputRef={abbrRef} /></Grid>
                        <Grid item xs={12} className={classes.buttonContainer}><Button type="submit" variant="contained" color="primary">Found</Button></Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>}
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    </Grid>
}