import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => createStyles({
    container: {
        flexDirection: 'row',
        gridAutoRows: '1fr'
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    bottomAction: {
        alignItems: 'end',
        justifyContent: 'flex-end'
    }
}));