import { Hidden, List, ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { ArrowBackIos as ArrowBackIosIcon, ArrowRight as IconArrowRight } from '@material-ui/icons';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { styles } from './Navigation.style';

export interface NavigationProperties {
    horizontal?: boolean;
    onCloseClick?: () => void
}

const navItems = [
    {title: 'News', link: '/news'},
    {title: 'Player', link: '/player'},
    {title: 'Fight', link: '/fight'},
    {title: 'Info', link: '/info', hidden: {lgUp: true}},
    //{title: 'Game', link: '/game'},
    {title: 'FAQ', link: '/faq'},
    {title: 'Legal', link: '/legal'}
]

export const Navigation: FunctionComponent<NavigationProperties> = ({ horizontal, onCloseClick }): JSX.Element => {
    const classes = styles();
    const listStyle = horizontal ? `${classes.list} ${classes.horizontal}` : classes.list;
    return <List className={listStyle}>
        <Hidden mdUp>
            <ListItem key="close" button onClick={onCloseClick}>
                <ArrowBackIosIcon />
            </ListItem>
        </Hidden>
        {navItems.map((item) => !item.hidden ?
            <ListItem key={item.title.toLowerCase()} button component={Link} to={item.link} onClick={onCloseClick}>
                <ListItemIcon>
                    <IconArrowRight />
                </ListItemIcon>
                <Typography className={classes.text}>{item.title}</Typography>
            </ListItem> :
            <Hidden key={'hidden' + item.title.toLowerCase()} {...item.hidden}>
                <ListItem key={item.title.toLowerCase()} button component={Link} to={item.link} onClick={onCloseClick}>
                    <ListItemIcon>
                        <IconArrowRight />
                    </ListItemIcon>
                    <Typography className={classes.text}>{item.title}</Typography>
                </ListItem>
            </Hidden>
        )}
    </List>;
}