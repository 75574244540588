import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { getRgbColorStringWithOpacity } from "../Theme";

export const styles = makeStyles((theme: Theme) => createStyles({
    list: {
        backgroundColor: getRgbColorStringWithOpacity('primaryPetrol', .6),
        backgroundImage: `
            repeating-linear-gradient(
                to bottom,
                ${getRgbColorStringWithOpacity('accentBlue', .8)} 0%,
                transparent 2px
            ),
            repeating-radial-gradient(
                ellipse at 0 100%,
                ${getRgbColorStringWithOpacity('accentDark', .8)} 0,
                transparent
            )
        `,
        color: theme.palette.text.primary,
        height: '100%',
        [theme.breakpoints.down('md')]: {
            width: '50vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw'
        }
    },
    text: {
        textShadow: '.2em .2em .2em rgb(0, 0, 0)'
    },
    icon: {
        boxShadow: '.2em .2em .2em rgb(0, 0, 0)'
    },
    horizontal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '2rem'
    }
}));