import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: 0
    },
    paper: {
        margin: 0,
        padding: 0
    },
    table: {
        overflow: 'hidden'
    },
    head: {
        marginBottom: theme.spacing(2)
    },
    headInner: {
        padding: theme.spacing(2)
    },
    title: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    hint: {
        fontSize: '.8rem'
    },
    buttonContainer: {
        display: 'flex',
        padding: theme.spacing(2),
        flex: '0 0 auto',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    }
}));