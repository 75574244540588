import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => createStyles({
    title: {
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    badge: {
        background: theme.palette.primary.dark
    },
    image: {
        width: '80%',
        height: 'auto',
        objectFit: 'contain'
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        objectFit: 'contain'
    }
}));