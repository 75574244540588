import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getRgbColorStringWithOpacity } from '../../Theme';

export const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(2, 2, 0, 0),
        padding: '0!important'
    },
    paper: {
        padding: theme.spacing(2),
        margin: 0
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    subheader: {
        fontSize: '1rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)'
    },
    pointer: {
        cursor: 'pointer'
    },
    selectedItem: {
        background: getRgbColorStringWithOpacity('primaryPetrol', .2)
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        flex: '0 0 auto',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    }
}));