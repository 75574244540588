import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => createStyles({
    title: {
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        }
    },
    card: {
        marginBottom: theme.spacing(2)
    },
    smallHint: {
        fontSize: '.8rem'
    },
    linkText: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: '.8rem',
        verticalAlign: 'middle'
    },
    numberText: {
        fontSize: '1.1rem',
        verticalAlign: 'top'
    },
    numberIcon: {
        verticalAlign: 'top'
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));