import { Card, CardActions, CardContent, CardHeader, Grid, Icon, Typography } from "@material-ui/core";
import { CheckCircleOutline as CheckCircleOutlineIcon, HelpOutline as HelpOutlineIcon } from "@material-ui/icons";
import { FunctionComponent } from "react";
import { styles } from "./Faq.style";

const qa = [
    {
        q: `You look and act like pro gamer! I'm really impressed! WOW!`,
        a: `Yes we are almost the most professional clan in the history of Word of W… Counter Strike. Our very big success is based on historical big success and many many hours of thinking about our big success.`
    }, {
        q: `Does your clan leader really still have the same hairstyle he had in school?`,
        a: `That's a wonderful question. Indeed he does and we love him for the fact, he currently dresses up like in schooldays. He also wears his bumbag and drives his Opel Corsa. BEST CAR EVER!`
    }, /*{
        q: `Is it true, that one of your players is male but has a pussy?`,
        a: `STOP! Female or male does not exist in our vocabulary! We all are human beings and this one special player did not find himself yet. We are a diverse team and this team lives from diversity.`
    },*/ {
        q: 'Some say that MTW is a much better Counter Strike Clan than yours.',
        a: 'Look, success can be measured in different ways. We are the best clan in the world, these are not alternative facts, Kellyanne says, this is the truth.'
    }, {
        q: `You are playing together as a team since schooldays, right?`,
        a: `Never stop playing. Even if we are married, have children, became fat and going to bed before 12, we love the game and nothing can never ever stop us.`
    }, {
        q: 'Did you ever play other games than Counter Strike?',
        a: 'Do other games exist? No, none of us, not one single team member did ever play another game. I swear by the moon and the stars and the sky!'
    }, {
        q: `Can I become a member of your pro clan? I'm a very good player, almost as good as Semmel.`,
        a: `That's not possible.`
    }
]

export const Faq: FunctionComponent = (): JSX.Element => {
    const classes = styles();
    return <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>FAQ</Typography>
        </Grid>
        {qa.map((s, i) => <Grid item xs={12} md={6} lg={4} key={i}>
            <Card>
                <CardHeader title={s.q} avatar={<Icon><HelpOutlineIcon /></Icon>} />
                <CardContent>
                    <Typography>{s.a}</Typography>
                    
                </CardContent>
                <CardActions className={classes.bottomAction}>
                    <Typography>Answered</Typography>
                    <Icon color={"secondary"}>
                        <CheckCircleOutlineIcon />
                    </Icon>
                </CardActions>
            </Card>
        </Grid>)}
    </Grid>;
}