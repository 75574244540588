import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const colors: {[name: string]: Color} = {
  primaryPetrol: [17, 118, 107],
  secondaryMint: [29, 233, 182],
  accentBlue: [23, 0, 74],
  accentDark: [27, 36, 34],
  accentPink: [209, 0, 146],
  accentOrange: [255, 153, 0]
};

export const theme: Theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#11766B',
    },
    secondary: {
      main: '#1de9b6',
    },
    background: {
      default: '#1B2422',
      paper: '#1B2422'
    }
  }
});

export function getRgbColorString (colorName: string): string {
  if (colors[colorName]) {
    return `rgb(${colors[colorName][0]}, ${colors[colorName][1]}, ${colors[colorName][2]})`;
  } else return 'rgb(0,0,0)';
}

export function getRgbColorStringWithOpacity (colorName: string, opacity: number): string {
  if (colors[colorName]) {
    return `rgba(${colors[colorName][0]}, ${colors[colorName][1]}, ${colors[colorName][2]}, ${opacity})`;
  } else return 'rgba(0,0,0,0)';
}

type Color = [number, number, number];