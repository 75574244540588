import { Box, Button, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { HttpError } from '@sml86/httpjs';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Wars, War as WarData } from 'shared';
import { API } from '../../api/API';
import { styles } from './War.style';

const ROWS_PER_PAGE: number = 6;

export const War: FunctionComponent = (): JSX.Element => {
    const [wars, setWars] = useState<Wars>();
    const [page, setPage] = useState<number>(0);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<'success'|'warning'|'error'>();
    const [message, setMessage] = useState<string>();
    const classes = styles();

    async function getWars (): Promise<void> {
        try {
            const wars: Wars = await API.getMyWars();
            setWars(wars);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {

            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    function getState (war: WarData): string {
        if (!war.ended) return 'challenged';
        else if (war.ended && war.winner) {
            war.ended = new Date(war.ended);
            if (war.ended > new Date()) return 'fighting';
            else return war.winner;
        } else return '-';
    }

    useEffect(() => {
        getWars();
    }, []);

    return <Grid item xs={12} className={classes.root}>
        <Paper className={classes.paper}>
            <Typography className={classes.title}>Clan Wars</Typography>
            {wars && <>
                <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Challenger</TableCell>
                                <TableCell>Opponent</TableCell>
                                <TableCell>Winner</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {wars.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE).map((war: WarData) => <TableRow key={war.id}>
                                    <TableCell><Link to={`/game/war/${war.id}`} className={classes.link}>{war.challenger.name}</Link></TableCell>
                                    <TableCell><Link to={`/game/war/${war.id}`} className={classes.link}>{war.opponent.name}</Link></TableCell>
                                    <TableCell><Link to={`/game/war/${war.id}`} className={classes.link}>{getState(war)}</Link></TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination component="div" count={wars.length} rowsPerPage={ROWS_PER_PAGE} page={page} onChangePage={(e: unknown, p: number) => setPage(p)} rowsPerPageOptions={[]}/>
            </>}
            {window.user?.clan && <Box component="div" pt={2} className={classes.buttonContainer}>
                <Button variant="contained" color="primary" component={Link} to="/game/challenge">Challenge</Button>
            </Box>}
        </Paper>
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
        <Alert severity={severity}>
            {message}
        </Alert>
        </Snackbar>
    </Grid>
}