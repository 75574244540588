import { FunctionComponent } from "react";
import { styles } from "./Videobackground.style";

export const Videobackground: FunctionComponent = (): JSX.Element => {
    const classes = styles();
    return <div className={classes.root}>
        <video playsInline autoPlay muted loop controls={false} className={classes.video}>
            <source src="video/SFCS.webm" type="video/webm" />
            <source src="video/SFCS.mp4" type="video/mp4" />
        </video>
        <div className={classes.overlay}></div>
    </div>
}