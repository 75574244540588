import { HttpMethod, HttpError, HttpHeader, MimeType, Http,  } from '@sml86/httpjs';
import { Clan, Clans, Info, Rankings, Track, TrackResponse, War, WarMode, Wars } from 'shared';

const base: string = '/api';
const CONTENT_TYPE_FORM: [string, string] = [HttpHeader.CONTENT_TYPE, MimeType.FormUrlEncoded];
const CONTENT_TYPE_JSON: [string, string] = [HttpHeader.CONTENT_TYPE, MimeType.JSON];
const CONTENT_TYPE_TEXT: [string, string] = [HttpHeader.CONTENT_TYPE, MimeType.Text];

export class API {

    public static get AUTH (): [string, string] {
        return [HttpHeader.AUTHORIZATION, `Bearer ${window.bearer}`];
    }

    public static async getTrackData (fingerprint: string): Promise<TrackResponse> {
        try {
            return await fetch(`${base}/track?fingerprint=${fingerprint}`, {
                method: HttpMethod.GET
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<TrackResponse>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async sendTrackData (crack: string): Promise<Track> {
        try {
            return await fetch(`${base}/track`, {
                method: HttpMethod.POST,
                headers: [CONTENT_TYPE_TEXT],
                body: crack
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Track>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async deleteTrackData (fingerprint: string): Promise<void> {
        try {
            return await fetch(`${base}/track?fingerprint=${fingerprint}`, {
                method: HttpMethod.DELETE
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return Promise.resolve();
            });
        } catch (error) {
            throw error;
        }
    }

    public static async getServerInfo (): Promise<Info> {
        try {
            return await fetch(`${base}/info`, {
                method: HttpMethod.GET
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Info>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async authenticate (email: string, password: string): Promise<string> {
        try {
            const bearer: string = await fetch(`${base}/login`, {
                method: HttpMethod.POST,
                headers: [API.AUTH, CONTENT_TYPE_FORM],
                body: Http.getFormUrlEncoded({email, password})
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.text();
            });
            window.bearer = bearer;
            return bearer;
        } catch (error) {
            throw error;
        }
    }

    public static async getClan (): Promise<Clan> {
        try {
            return await fetch(`${base}/game`, {
                method: HttpMethod.GET,
                headers: [API.AUTH]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Clan>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async getClanByName (clan: string): Promise<Clan> {
        try {
            return await fetch(`${base}/game/clan/${clan}`, {
                method: HttpMethod.GET,
                headers: [API.AUTH]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Clan>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async getClans (): Promise<Clans> {
        try {
            return await fetch(`${base}/game/clans`, {
                method: HttpMethod.GET,
                headers: [API.AUTH]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Clans>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async getMyWars (): Promise<Wars> {
        try {
            return await fetch(`${base}/game/wars/my`, {
                method: HttpMethod.GET,
                headers: [API.AUTH]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Wars>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async getRankings (): Promise<Rankings> {
        try {
            return await fetch(`${base}/game/rankings`, {
                method: HttpMethod.GET,
                headers: [API.AUTH]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Rankings>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async getWarById (war: number): Promise<War> {
        try {
            return await fetch(`${base}/game/war/${war}`, {
                method: HttpMethod.GET,
                headers: [API.AUTH]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<War>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async createClan (name: string, abbr: string): Promise<Clan> {
        try {
            return await fetch(`${base}/game/clan`, {
                method: HttpMethod.POST,
                headers: [API.AUTH, CONTENT_TYPE_JSON],
                body: JSON.stringify({name, abbr})
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<Clan>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async createWar (opponent: number, mode: WarMode): Promise<War> {
        try {
            return await fetch(`${base}/game/war`, {
                method: HttpMethod.POST,
                headers: [API.AUTH, CONTENT_TYPE_JSON],
                body: JSON.stringify({opponent, mode})
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.json() as Promise<War>;
            });
        } catch (error) {
            throw error;
        }
    }

    public static async addPlayerToWar (war: number, player: number): Promise<string> {
        try {
            return await fetch(`${base}/game/war/${war}`, {
                method: HttpMethod.POST,
                headers: [API.AUTH, CONTENT_TYPE_JSON],
                body: JSON.stringify({player})
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.text();
            });
        } catch (error) {
            throw error;
        }
    }

    public static async deletePlayerFromWar (war: number, player: number): Promise<string> {
        try {
            return await fetch(`${base}/game/war/${war}`, {
                method: HttpMethod.DELETE,
                headers: [API.AUTH, CONTENT_TYPE_JSON],
                body: JSON.stringify({player})
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.text();
            });
        } catch (error) {
            throw error;
        }
    }

    public static async startWar (war: number): Promise<Date> {
        try {
            const end: string = await fetch(`${base}/game/war/${war}`, {
                method: HttpMethod.PUT,
                headers: [API.AUTH, CONTENT_TYPE_JSON]
            }).then((response: Response) => {
                if (!response.ok) throw new HttpError(response.status, response.statusText);
                return response.text();
            });
            return new Date(parseInt(end));
        } catch (error) {
            throw error;
        }
    }
}