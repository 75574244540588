import { GridList, GridListTile, GridListTileBar, IconButton } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { FunctionComponent } from 'react';
import { styles } from './News.style';

export const News: FunctionComponent = (): JSX.Element => {
    const classes = styles();
    return <GridList cellHeight={256} className={classes.grid} cols={3}>
        <GridListTile className={classes.tile} cols={3} rows={2}>
            <img src="images/20210122152103_1.jpg" alt="Counter Strike" />
            <GridListTileBar
                title="SuperSniper shooting back"
                subtitle="By Semmel, 2021/01/21"
                actionIcon={
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                }
                className={classes.bar}
            />
        </GridListTile>
        <GridListTile className={classes.tile} cols={2} rows={1}>
            <img src="images/20210122160155_1.jpg" alt="Counter Strike" />
            <GridListTileBar
                title="Something's in the air tonight"
                subtitle="By Semmel, 2021/01/18"
                actionIcon={
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                }
                className={classes.bar}
            />
        </GridListTile>^
        <GridListTile className={classes.tile} cols={1} rows={1}>
            <img src="images/20210122160951_1.jpg" alt="Counter Strike" />
            <GridListTileBar
                title="New Server arrived"
                subtitle="By Begga, 2021/01/17"
                actionIcon={
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                }
                className={classes.bar}
            />
        </GridListTile>
    </GridList>
}