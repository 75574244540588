import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getRgbColorString } from '../../Theme';

export const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: 0
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '2rem',
        textShadow: '.2em .2em .2em rgb(0, 0, 0)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(2)
        },
        padding: theme.spacing(2)
    },
    gold: {
        color: getRgbColorString('accentOrange')
    },
    silver: {
        color: 'silver'
    },
    bronze: {
        color: 'rgb(204, 142, 52)'
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: getRgbColorString('accentBlue'),
        color: theme.palette.text.primary
    }
}));