import { Avatar, Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Typography } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { HttpError, HttpStatus } from '@sml86/httpjs';
import { FunctionComponent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Clan, Clans, Player, War, WarMode } from 'shared';
import { API } from '../../api/API';
import { styles } from './Challenge.style';

export const Challenge: FunctionComponent = (): JSX.Element => {
    const [clans, setClans] = useState<Clans>();
    const [selectedClan, setSelectedClan] = useState<Clan>();
    const [mode, setMode] = useState<WarMode>(WarMode.ThreeOnThree);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<'success'|'warning'|'error'>();
    const [message, setMessage] = useState<string>();
    const [redirect, setRedirect] = useState<number>();
    const classes = styles();

    async function getClans (): Promise<void> {
        try {
            const clans: Clans = await API.getClans();
            setClans(clans);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {

            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    async function createWar (): Promise<void> {
        if (!selectedClan) return;
        try {
            const war: War = await API.createWar(selectedClan.id, mode);
            setRedirect(war.id);
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {
                if (error.status === HttpStatus.Conflict) {
                    setMessage('You cannot fight the same clan twice a week.');
                }
            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    function handleListSelection (index: number): void {
        clans && setSelectedClan(clans[index]);
    }

    useEffect(() => {
        getClans();
    }, []);

    return <Grid item xs={12} className={classes.root}>
        <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h4">Challenge</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {clans && <Box boxShadow={4}>
                            <List>
                            {clans.map((clan: Clan, index: number) => window.user?.id !== clan.owner && <ListItem
                                key={`c${clan.id}`}
                                onClick={() => handleListSelection(index)}
                                className={clan === selectedClan ? classes.selectedItem : classes.pointer}>
                                <ListItemText>{clan.name}</ListItemText>
                            </ListItem>)}
                        </List>
                    </Box>}
                </Grid>
                <Grid item xs={12} md={6}>
                {selectedClan && <Box boxShadow={4}>
                        <List>
                            {selectedClan.players.map((player: Player) => <ListItem key={`p${player.id}`}>
                                <ListItemAvatar><Avatar color="primary"><PersonIcon /></Avatar></ListItemAvatar>
                                <ListItemText primary={player.name} secondary={`Level ${player.level}`}></ListItemText>
                            </ListItem>)}
                        </List>
                    </Box>}
                </Grid>
                <Grid item xs={12} md={6} container spacing={2}>
                    <Grid item xs={4}>
                        <Box boxShadow={4} p={2} onClick={() => setMode(WarMode.TwoOnTwo)} className={mode === WarMode.TwoOnTwo ? classes.selectedItem : classes.pointer}><Typography>Two vs. Two</Typography></Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box boxShadow={4} p={2} onClick={() => setMode(WarMode.ThreeOnThree)} className={mode === WarMode.ThreeOnThree ? classes.selectedItem : classes.pointer}><Typography>Three vs. Three</Typography></Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box boxShadow={4} p={2} onClick={() => setMode(WarMode.FiveOnFive)} className={mode === WarMode.FiveOnFive ? classes.selectedItem : classes.pointer}><Typography>Five vs. Five</Typography></Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" disabled={!selectedClan} onClick={() => createWar()}>Save</Button>
                </Grid>
            </Grid>
        </Paper>
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
        {redirect && <Redirect to={`/game/war/${redirect}`} />}
    </Grid>
}