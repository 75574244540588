import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { getRgbColorString, getRgbColorStringWithOpacity } from "./Theme";

export const styles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: 'fixed',
        height: '100vh',
        padding: 0,
        margin: 0
    },
    header: {
        margin: 0,
        backgroundImage: `
            repeating-linear-gradient(
                to bottom,
                ${getRgbColorString('accentBlue')} 0%,
                transparent 2px
            ),
            repeating-linear-gradient(
                to left,
                ${getRgbColorStringWithOpacity('accentDark', .4)} 0%,
                transparent
            ),
            repeating-radial-gradient(
                ellipse at 0 100%,
                ${getRgbColorStringWithOpacity('primaryPetrol', .6)} 0,
                transparent
            )`,
        backgroundColor: getRgbColorStringWithOpacity('primaryPetrol', .5)
    },
    drawer: {
        width: '100%',
        right: 0
    },
    content: {
        height: '100vh',
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            height: `calc(100vh - ${theme.spacing(7)})`,
            paddingBottom: theme.spacing(30)
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 10%'
        },
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    headline: {
        width: '100%',
        textAlign: 'center',
        textShadow: `
            2px 2px .2em ${getRgbColorString('primaryPetrol')},
            .3em .3em .3em rgb(0, 0, 0)
        `,
        fontSize: '2.2rem'
    },
    titlebar: {
        height: '20%'
    },
    logoContainer: {
        height: 'calc(100% - 3rem)',
        width: '100%'
    },
    logo: {
        width: 'auto',
        height: '80%',
        margin: '.5rem 0',
        float: 'right',
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            height: theme.spacing(8),
            float: 'none',
            flex: 1
        }
    },
    contentArea: {
        gridAutoRows: '1fr',
        minHeight: 'calc(100vh - 20% - 1px)'
    },
    gridContent: {
        minHeight: '100%',
        paddingRight: theme.spacing(1),
        margin: 0
    },
    infoArea: {
        height: '100%',
        margin: 0,
        padding: 0,
        backgroundColor: getRgbColorStringWithOpacity('accentBlue', .2),
        backgroundImage: `
            repeating-linear-gradient(
                to bottom,
                ${getRgbColorStringWithOpacity('accentBlue', .2)} 0%,
                transparent 2px
            )`
    },
    dial: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1)
    },
    dialTooltip: {
        display: 'none'
    }
}));