import { Card, CardContent, Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@material-ui/core';
import { Public as PublicIcon, People as PeopleIcon, Person as PersonIcon, Face as FaceIcon, PersonOutline as PersonOutlineIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { FunctionComponent, useEffect, useState } from 'react';
import { TrackResponse } from 'shared';
import { API } from '../api/API';
import { styles } from './Legal.style';

export interface LegalProperties {
    fingerprint?: string;
}

export const Legal: FunctionComponent<LegalProperties> = ({fingerprint}): JSX.Element => {
    const [trackData, setTrackData] = useState<TrackResponse>();
    const classes = styles();

    const handleForgetClick = async () => {
        if (fingerprint) {
            try {
                await API.deleteTrackData(fingerprint);
                const data: TrackResponse = await API.getTrackData(fingerprint);
                setTrackData(data);
            } catch (error) {
                // ignore
            }
        }
    }

    useEffect(() => {
        if (fingerprint) {
            API.getTrackData(fingerprint).then((data: TrackResponse) => {
                setTrackData(data);
            }).catch(() => {});
        }
    }, [fingerprint]);

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>Legal</Typography>
        </Grid>
        <Grid item xs={12}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography>
                        Nobody likes legal stuff. We don't care about legal stuff. Legal stuff sucks. If you want to sue against us, feel free and have fun. We prefer real wars in Counter Strike!
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Disclaimer</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.smallHint}>
                            This whole online presence is meant satirically, ironic and sarcastic &ndash; big suprise. We are not a pro clan &ndash; WHAAT? &ndash; and none of the listed "sponsors" does really sponsor us!
                            But we bought the great equipment from them, great stuff. Best. But Semmel is our best player, that is really true. Just kidding, but true.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Contact Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.smallHint}>You seriously want to contact us? Okay spoilsport, just click the link below to get our contact information.</Typography>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button variant="contained" color="primary" size="small" startIcon={<PublicIcon />} onClick={() => window.open('https://www.denic.de/webwhois-web20/?domain=cloudicator.de')}>Click Here!</Button>
                    </AccordionActions>
                </Accordion>
                {trackData && <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Tracking Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography className={classes.numberText}><PeopleIcon className={classes.numberIcon} /> {trackData.total}</Typography>
                                <Typography className={classes.smallHint}>Total visitors</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.numberText}><PersonIcon className={classes.numberIcon} /> {trackData.recurring}</Typography>
                                <Typography className={classes.smallHint}>Recurring visitors</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.numberText}><FaceIcon className={classes.numberIcon} /> {trackData.user}</Typography>
                                <Typography className={classes.smallHint}>Your tracked visits</Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions className={classes.actions}>
                        <Button variant="contained" color="primary" size="small" startIcon={<PersonOutlineIcon />} onClick={handleForgetClick}>Forget Me!</Button>
                    </AccordionActions>
                </Accordion>}
            </Card>
        </Grid>
    </Grid>;
}