import { AppBar, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, Hidden, IconButton, ThemeProvider, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Track } from 'shared/Track';
import { theme } from './Theme';
import { styles } from './App.style';
import { API } from './api/API';
import { Navigation } from './navigation/Navigation';
import { News } from './news/News';
import { Infobar } from './infobar/Infobar';
import { Player } from './player/Player';
import { Legal } from './legal/Legal';
import { Videobackground } from './videoheader/Videobackground';
import { Faq } from './faq/Faq';
import { Fight } from './fight/Fight';
import { Crack } from './crack/Crack';
import { Game } from './game/Game';

export const App: FunctionComponent = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(true);
  const [fingerprint, setFingerprint] = useState<string>();
  const crackRef = useRef<string>('');
  const classes = styles();

  useEffect(() => {
      API.sendTrackData(crackRef.current).then((track: Track) => {
        setFingerprint(track.fingerprint);
      }).catch(() => {});
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Hidden lgDown>
          <Videobackground />
        </Hidden>
        <Container className={classes.container} maxWidth={false}>
          <Hidden lgUp>
            <AppBar position="static" className={classes.header}>
              <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMenuOpen(true)}>
                  <MenuIcon />
                </IconButton>
                <Drawer open={menuOpen} onClose={() => setMenuOpen(false)} className={classes.drawer}>
                  <Navigation onCloseClick={() => setMenuOpen(false)} />
                </Drawer>
                <img src="images/SF_logo.svg" alt="SF Logo" className={classes.logo} />
              </Toolbar>
            </AppBar>
          </Hidden>
          <Grid container spacing={0} className={classes.content}>
            <Hidden lgDown>
              <Grid item lg={12} className={classes.titlebar}>
                <div className={classes.logoContainer}>
                  <img src="images/SF_logo.svg" alt="SF Logo" className={classes.logo} />
                </div>
                <Navigation horizontal />
              </Grid>
            </Hidden>
            <Grid item xs={12} container spacing={0} className={classes.contentArea}>
              <Grid item xs={12} lg={10} className={classes.gridContent}>
                <Route path="/" exact component={News} />
                <Route path="/news" component={News} />
                <Route path="/player" component={Player} />
                <Route path="/fight" component={Fight} />
                <Route path="/game" component={Game} />
                <Route path="/info" component={Infobar} />
                <Route path="/faq" component={Faq} />
                <Route path="/legal" render={() => <Legal fingerprint={fingerprint} />} />
              </Grid>
              <Hidden lgDown>
                <Grid item lg={2} className={classes.infoArea}>
                  <Infobar />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} disableBackdropClick>
          <DialogTitle>We use very bad cookies, the most bad cookies in the world!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Actually we do not use cookies, because we do not care about your personal data. Maybe in the future, but only to remember, if you have seen this stupid dialog before.
              We just want to show this stupid dialog, because it looks like more professional. That is true. All.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {window.location.href = 'about:blank'}}>Disagree</Button>
            <Button onClick={() => setDialogOpen(false)} color="secondary">Agree</Button>
          </DialogActions>
        </Dialog>
        <Crack crackRef={crackRef} />
      </Router>
    </ThemeProvider>
  );
}
