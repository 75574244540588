import { Grid, Paper, Slider, TextField, Typography, MenuItem, Divider, Switch, Button, Snackbar } from "@material-ui/core";
import { Alert, AlertTitle, Rating } from "@material-ui/lab";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { FunctionComponent, useState } from "react";
import { styles } from "./Fight.style";

export const Fight: FunctionComponent = (): JSX.Element => {
    const [skill, setSkill] = useState<number>(20);
    const [mode, setMode] = useState<string>('3on3');
    const [tshirt, setTshirt] = useState<string>('XXL');
    const [fanBoy, setFanBoy] = useState<number>(5);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const classes = styles();
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>Fight</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h3" className={classes.subtitle}>Don't you dare!</Typography>
        </Grid>
        <Grid item xs={12}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Paper>
                    <Grid container spacing={2} className={classes.formContainer}>
                        <Grid item xs={12}>
                            <Typography>Please fill in all required information and we'll proceed your request. But don't be to curious, we are very busy with doing our great stuff.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.sectiontitle}>General Information</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs><TextField name="name" label="Clan Name" autoComplete="off" helperText="e.g. Lucky Loser" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs><TextField name="mode" label="Select" value={mode} defaultValue={mode} select onChange={(e) => setMode(e.target.value)} autoComplete="off" helperText="Choose Wisely">
                                <MenuItem value="2on2">2 on 2</MenuItem>
                                <MenuItem value="3on3">3 on 3</MenuItem>
                                <MenuItem value="4on4">4 on 4</MenuItem>
                                <MenuItem value="5on5">5 on 5</MenuItem>
                                <MenuItem value="6on6">6 on 6</MenuItem>
                                <MenuItem value="aak">All Against Kudde</MenuItem>
                            </TextField></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>Skill</Typography></Grid>
                            <Grid item><RemoveIcon /></Grid>
                            <Grid item xs><Slider value={skill} defaultValue={skill} onChange={(_e, v) => setSkill(v as number)} /></Grid>
                            <Grid item><AddIcon /></Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.sectiontitle}>Contact Information</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs><TextField name="email" label="Email Address" autoComplete="off" helperText="e.g. cool.leader@lucky-loser.org" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs><TextField label="Phone Number" autoComplete="off" helperText="With Country Code" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs><TextField name="tshirt" value={tshirt} defaultValue={tshirt} label="Tshirt Size" autoComplete="off" helperText="If you're too fat, we're afraid" select onChange={(e) => setTshirt(e.target.value)}>
                                <MenuItem value="XS">XS</MenuItem>
                                <MenuItem value="S">S</MenuItem>
                                <MenuItem value="M">M</MenuItem>
                                <MenuItem value="L">L</MenuItem>
                                <MenuItem value="XL">XL</MenuItem>
                                <MenuItem value="XXL">XXL</MenuItem>
                                <MenuItem value="XXXL">XXXL</MenuItem>
                                <MenuItem value="XXXXL">XXXXL</MenuItem>
                                <MenuItem value="TOOFAT">TOO FAT</MenuItem>
                            </TextField></Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.sectiontitle}>Additional Information</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>Pro Game Experience</Typography></Grid>
                            <Grid item xs><Rating name="exp" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>Pro Player Experience</Typography></Grid>
                            <Grid item xs><Rating name="pro" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>Semmel Fanboy</Typography></Grid>
                            <Grid item xs><Rating name="fan" value={fanBoy} defaultValue={fanBoy} onChange={() => setFanBoy(5)} /></Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>I hereby confirm that all filled in data is correct</Typography></Grid>
                            <Grid item xs><Switch name="correct" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>I hereby confirm that we never had and we never will cheat</Typography></Grid>
                            <Grid item xs><Switch name="cheat" /></Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} container spacing={1}>
                            <Grid item xs={12}><Typography className={classes.label}>I hereby confirm that I've read the terms and conditions and I'm willing in</Typography></Grid>
                            <Grid item xs><Switch name="tca" /></Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs container direction="row-reverse">
                                <Button variant="contained" color="primary" onClick={() => setShowAlert(true)}>Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <Alert severity="success">
                    <AlertTitle>Thank You!</AlertTitle>
                    Your request was not send! Are you serious? Play with bots, noob!
                </Alert>
            </Snackbar>
        </Grid>
    </Grid>;
}