import { FunctionComponent, useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Constants, Token, User } from 'shared';
import { Grid, Hidden, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ArrowRight as IconArrowRight } from '@material-ui/icons';
import { styles } from './Game.style';
import { Login } from './login/Login';
import { Clan } from './clan/Clan';
import { War } from './war/War';
import { Challenge } from './challenge/Challenge';
import { WarDetails } from './war/WarDetails';
import { Ranking } from './Ranking/Ranking';

export const Game: FunctionComponent = (): JSX.Element => {
    const [auth, setAuth] = useState<boolean>(false);
    const classes = styles();

    function getCookie (): void {
        try {
            const pattern: RegExp = new RegExp(`(?:(?:^|.*;\s*)${Constants.JWT_COOKIE_NAME}\s*\=\s*([^;]*).*$)|^.*$`);
            const bearer: string = document.cookie.replace(pattern, '$1');
            window.bearer = bearer;
            window.user = (JSON.parse(atob(bearer.split('.')[1])) as Token).user as User;
        } catch (error) {
        }
    }

    function logout (): JSX.Element {
        delete window.bearer;
        delete window.user;
        setAuth(false);
        return <></>;
    }

    useEffect(() => {
        getCookie();
        if (window.bearer) {
            setAuth(true);
        }
    }, []);

    return auth ? 
        <Grid container spacing={2} className={classes.root}>
            <Hidden mdUp>
                <Grid item xs={12} className={classes.menuContainer}>
                    <List className={classes.mobileMenu}>
                            <ListItem button component={Link} to="/game/clan"><ListItemIcon><IconArrowRight /></ListItemIcon><ListItemText>Clan</ListItemText></ListItem>
                            <ListItem button component={Link} to="/game/war"><ListItemIcon><IconArrowRight /></ListItemIcon><ListItemText>War</ListItemText></ListItem>
                            <ListItem button component={Link} to="/game/ranking"><ListItemIcon><IconArrowRight /></ListItemIcon><ListItemText>Ranking</ListItemText></ListItem>
                    </List>
                </Grid>
            </Hidden>
            <Switch>
                <Route path="/game" exact={true} render={() =>
                    <>
                        <Grid item xs={12} md={4} style={{paddingLeft: 0}}>
                            <Clan />
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={12} md={4} style={{paddingLeft: 0}}>
                                <War />
                            </Grid>
                            <Grid item xs={12} md={4} style={{paddingLeft: 0}}>
                                <Ranking />
                            </Grid>
                        </Hidden>
                    </>
                } />
                <Route path="/game/war/:war" component={WarDetails} />
                <Route path="/game/war" exact={true} component={War} />
                <Route path="/game/clan" component={Clan} />
                <Route path="/game/ranking" component={Ranking} />
                <Route path="/game/challenge" component={Challenge} />
                <Route path="/game/logout" exact={true} component={logout} />
            </Switch>
        </Grid> : <Login handleAuthentication={() => setAuth(true)} />;
}