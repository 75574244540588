import { FunctionComponent } from "react";

export const MedalIcon: FunctionComponent = (): JSX.Element => <svg
  className="MuiSvgIcon-root"
  width="24"
  height="24"
  viewBox="0 0 24 24">
  <path
     d="m 12,12 c -3.314,0 -6,2.687 -6,6 0,3.313 2.686,6 6,6 3.314,0 6,-2.687 6,-6 0,-3.313 -2.686,-6 -6,-6 z m 0,11 c -2.757,0 -5,-2.243 -5,-5 0,-2.757 2.243,-5 5,-5 2.757,0 5,2.243 5,5 0,2.757 -2.243,5 -5,5 z m 0,-9 c -2.206,0 -4,1.794 -4,4 0,2.206 1.794,4 4,4 2.206,0 4,-1.794 4,-4 0,-2.206 -1.794,-4 -4,-4 z M 18.246,0 15.195,3 H 9 L 5.707,0 Z m 0.981,0.339 -6.605,9.692 c 1.112,0.086 2.161,0.397 3.101,0.893 L 21,3 Z M 4.751,0.374 3,3 8.234,10.946 c 0.934,-0.501 1.979,-0.818 3.088,-0.912 z"/>
</svg>
