import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getRgbColorStringWithOpacity } from '../Theme';

export const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        margin: 0
    },
    menuContainer: {
        padding: '0!important'
    },
    mobileMenu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '1.5rem',
        backgroundColor: getRgbColorStringWithOpacity('primaryDark', .6),
        backgroundImage: `
            repeating-linear-gradient(
                to bottom,
                ${getRgbColorStringWithOpacity('primaryPetrol', .8)} 0%,
                transparent 2px
            ),
            repeating-radial-gradient(
                ellipse at 0 100%,
                ${getRgbColorStringWithOpacity('accentDark', .8)} 0,
                transparent
            )
        `,
        color: theme.palette.text.primary
    }
}));