import { Avatar, Badge, Card, CardHeader, createStyles, Grid, Theme, Typography, withStyles } from "@material-ui/core";
import { FunctionComponent } from "react";
import { getRgbColorString, theme } from "../Theme";
import { styles } from "./Player.style";

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }),
)(Avatar);

const players = [
    {
        id: 1,
        name: 'DEnnis',
        description: 'Bold leader, everybody follows. Always wears his bumbag.',
        rank: 0,
        avatar: 'dennis.png'
    },
    {
        id: 2,
        name: 'Meizzen',
        description: 'Cool guy, everybody\'s darling, husband\'s nightmare.',
        rank: 1,
        avatar: 'meizzen.png'
    },
    {
        id: 3,
        name: 'Donald',
        description: 'Has a lot of spare time now. Likes cats.',
        rank: 5,
        avatar: 'donald.png'
    },
    {
        id: 4,
        name: 'Semmel',
        description: 'Probably has the best aim in Counter Strike. Because he\'s been a boyscout.',
        rank: 2,
        avatar: 'semmel.png'
    },
    {
        id: 5,
        name: 'Franze',
        description: 'Nearly nobody else moved more often. Where do you currently live?',
        rank: 4,
        avatar: 'franze.png'
    },
    {
        id: 6,
        name: 'Basti',
        description: 'Plays with track ball, due to issues with his wrist. *hrumph*',
        rank: 4,
        avatar: 'basti.png'
    },
    {
        id: 7,
        name: 'Kudde',
        description: 'Mother of Counter Strike. Ph. Double D.',
        rank: 3,
        avatar: 'kudde.png'
    },
    {
        id: 8,
        name: 'Misch',
        description: 'Looks even better than Ed Sheeran. Really.',
        rank: 4,
        avatar: 'misch.png'
    }
];

const ranks: string[] = [
    'US-O7_insignia.svg', // 0
    'US-O5_insignia.svg', // 1
    'US-O3_insignia.svg', // 2
    'Army-USA-OR-08a.svg', // 3
    'Army-USA-OR-04a.svg', // 4
    'Army-USA-OR-02.svg' // 5
];

export const Player: FunctionComponent = (): JSX.Element => {
    const classes = styles();
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>Player</Typography>
        </Grid>
        {players.map((player) => <Grid key={player.id} item xs={12} md={4} lg={3}>
            <Card>
                <CardHeader
                    avatar={
                        <Badge
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            badgeContent={<SmallAvatar src={`images/ranks/${ranks[player.rank]}`} className={classes.badge} imgProps={{className: classes.image}} />}>
                            <Avatar src={`images/avatar/${player.avatar}`} style={{background: getRgbColorString('accentPink'), color: theme.palette.common.white}} className={classes.avatar}>{player.name[0].toUpperCase()}</Avatar>
                        </Badge>
                    }
                    title={player.name}
                    subheader={player.description}
                />
            </Card>
        </Grid>)}
    </Grid>;
}