import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const styles = makeStyles((theme: Theme) => createStyles({
    grid: {
        width: '100%'
    },
    tile: {
        width: '100%',
        height: 'auto'
    },
    bar: {
        textShadow: '.2em .2em .2em rgb(0, 0, 0)'
    }
}));