import { Button, Grid, Hidden, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { HttpError, HttpStatus } from '@sml86/httpjs';
import { FormEvent, FunctionComponent, useRef, useState } from 'react';
import { Token, User } from 'shared';
import { API } from '../../api/API';
import { styles } from './Login.style';

interface LoginProperties {
    handleAuthentication: () => void;
}

export const Login: FunctionComponent<LoginProperties> = ({handleAuthentication}): JSX.Element => {
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<'success'|'warning'|'error'>();
    const [message, setMessage] = useState<string>();
    const emailRef = useRef<HTMLInputElement>();
    const passwordRef = useRef<HTMLInputElement>();

    const classes = styles();

    async function authenticate (): Promise<void> {
        try {
            if (emailRef.current?.value && passwordRef.current?.value) {
                const bearer: string = await API.authenticate(emailRef.current.value, passwordRef.current.value);
                window.bearer = bearer;
                window.user = (JSON.parse(atob(bearer.split('.')[1])) as Token).user as User;
                setSeverity('success');
                setMessage('You logged in successfully! You will be redirected in a few seconds.');
                setShowAlert(true);
                handleAuthentication();
            }
        } catch (error) {
            setSeverity('error');
            if (error instanceof HttpError) {
                if (error.status === HttpStatus.Forbidden) {
                    setMessage('Your email address or password is not correct.')
                }
            } else {
                setMessage(error.message);
            }
            setShowAlert(true);
        }
    }

    function handleSubmitForm (event: FormEvent): void {
        event.preventDefault();
        authenticate();
    }

    return <Grid container spacing={2} className={classes.root}>
        <Hidden lgDown>
            <Grid item xs md={4}></Grid>
        </Hidden>
        <Grid container spacing={2} item xs={12} md={4}>
            <Paper className={classes.container}>
                <Grid item xs={12}><Typography variant="h4" className={classes.title}>Log in</Typography></Grid>
                <form onSubmit={handleSubmitForm}>
                    <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <TextField label="Email Address" helperText="Your email address" name="email" inputRef={emailRef} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Password" helperText="Your password" name="password" type="password" inputRef={passwordRef} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" type="submit">Log in</Button>
                            </Grid>
                    </Grid>
                </form>
        </Paper>
        </Grid>
        <Hidden lgDown>
            <Grid item xs md={4}></Grid>
        </Hidden>
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    </Grid>;
}