import { Link, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { PlayCircleFilled as PlayCircleFilledIcon, PauseCircleFilled as PauseCircleFilledIcon, People as PeopleIcon, Explore as ExploreIcon, FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Info } from 'shared';
import { API } from '../api/API';
import { styles } from './Infobar.style';
import { Playerdialog } from './Playerdialog';

export const Infobar: FunctionComponent = (): JSX.Element => {
    const [info, setInfo] = useState<Info>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const intervalRef = useRef<number>();
    const classes = styles();

    async function updateServerInfo (): Promise<void> {
        try {
            const info: Info = await API.getServerInfo();
            setInfo(info);
        } catch (error) {
            setInfo(undefined);
        }
    }

    function handleDialogClose (): void {
        setDialogOpen(false);
    }

    useEffect(() => {
        updateServerInfo();
        intervalRef.current = window.setInterval(updateServerInfo, 30000);
        return () => {
            window.clearInterval(intervalRef.current);
        }
    }, []);

    return <List className={classes.area}>
        <ListItem>
            <Typography className={classes.headline}>{info ? info.name : 'Server Status'}</Typography>
        </ListItem>
        <ListItem>
            <ListItemIcon>
                {info ? <Link href="steam://connect/157.90.7.36:27015"><PlayCircleFilledIcon className={classes.iconGreen} /></Link> : <PauseCircleFilledIcon className={classes.iconRed} />}
            </ListItemIcon>
            <Typography className={classes.text}>{info ? 'Running' : 'Offline'}</Typography>
        </ListItem>
        <ListItem divider />
        <ListItem>
            <Typography className={classes.headline}>Player Online</Typography>
        </ListItem>
        <ListItem onClick={() => setDialogOpen(true)} className={classes.link}>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <Typography className={`${classes.text} ${classes.linkColor}`}>{info ? info.players.length : '-'}</Typography>
        </ListItem>
        <ListItem divider />
        <ListItem>
            <Typography className={classes.headline}>Current Map</Typography>
        </ListItem>
        <ListItem>
            <ListItemIcon>
                <ExploreIcon />
            </ListItemIcon>
            <Typography className={classes.text}>{info ? info.map : '-'}</Typography>
        </ListItem>
        <ListItem divider />
        <ListItem>
            <Typography className={classes.headline}>Sponsored By</Typography>
        </ListItem>
        <ListItem>
            <img src="intel_logo.png" alt="Intel Logo" srcSet="images/Intel_logo.svg" className={classes.logo} />
        </ListItem>
        <ListItem>
            <img src="nvidia_logo.png" alt="Intel Logo" srcSet="images/Nvidia_logo.svg" className={classes.logo} />
        </ListItem>
        <ListItem>
            <img src="asus_logo.png" alt="Intel Logo" srcSet="images/ASUS_logo.svg" className={classes.logo} />
        </ListItem>
        <ListItem>
            <img src="telekom_logo.png" alt="Intel Logo" srcSet="images/Telekom_logo.svg" className={classes.logo} />
        </ListItem>
        <ListItem>
            <img src="pornhub_logo.png" alt="Intel Logo" srcSet="images/Pornhub_logo.svg" className={classes.logo} />
        </ListItem>
        <ListItem>
            <ListItemIcon>
                <FavoriteBorderIcon className={classes.heart} />
            </ListItemIcon>
            <Typography className={classes.text}>Thank You!</Typography>
        </ListItem>
        <Playerdialog open={dialogOpen} handleClose={handleDialogClose} players={info?.players} />
    </List>
}